import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { Button } from "@/components/ui/button";
import { useHistory } from "react-router-dom";
import html2pdf from "html2pdf.js";
import { hideAgentIntentModal } from "../../store/actions/modalSetup";
import { removeString, toTwoDecimal } from "../../utils/commonFunctions";
import { requeryCoralTxn } from "../../store/actions/finalPayment";
import { txStatusQuery } from "../../store/actions/Coralpay";
import { WalletBalance } from "../../store/actions/wallet";
import { useReactToPrint } from "react-to-print";
import { Button } from "reactstrap";
import ReactToPrint from 'react-to-print-advanced';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export default function Receipt() {
  const dispatch = useDispatch();
  // const pdfRef = useRef(null);
  // const componentRef = useRef(null);
  const componentRef = useRef(null);
  // const handlePrint = useReactToPrint({ componentRef });
  const history = useHistory();
  const finalData = useSelector((state) => state.finalPayment);
  const transSuccess = useSelector((state) => state.trans.success);
  const modalSet = useSelector(state => state.modalSet);
  const requeryData = useSelector((state) => state.finalPayment.requery);
  const intentData = useSelector((state) => state.trans.nonUserIntent);
  const paymentIntent = useSelector((state) => state.trans.paymentIntent);
  const walletUserInit = useSelector((state) => state.trans.walletUserInit);
  const intent = useSelector((state) => state.trans);
  const tranStatus_query = useSelector(state => state.invokeCoralpay.txStatus_Query);
  const tranStatus_requery = useSelector(state => state.invokeCoralpay.txrequery);
  const wallet = useSelector(state => state.wallet);
  const agentWallet = useSelector(state => state.agentFunding);

  const [date, setDate] = useState("");
  const [email, setEmail] = useState(null);
  const [description, setDescription] = useState(null);
  const [customerName, setCustomerName] = useState(null);
  const [packageName, setPackageName] = useState(null);
  const [paymentRef, setpaymentRef] = useState(null);
  const [productLogo, setProductLogo] = useState(null);
  const [addr, setAddr] = useState(null);
  const [amount, setAmount] = useState(null);
  const [tokenData, setTokenData] = useState(null);
  const [units, setUnits] = useState(null);
  const [narration, setNarration] = useState(null);
  const [status, setStatus] = useState(null);
  const [fee, setFee] = useState(null);
  const [totalAmount, setTotalAmount] = useState(null);
  const [typeData, setTypeData] = useState(null);
  const [ktc1, setktc1] = useState(null);
  const [ktc2, setktc2] = useState(null);
  const [blocking, setBlocking] = useState(false);
  const [customerMessageData, setCustomerMessageData] = useState(null);

  const handleAfterPrint = useCallback(() => {
    console.log("onAfterPrint called");
  }, []);

  const handleBeforePrint = useCallback(() => {
    console.log(componentRef)
    console.log("onBeforePrint called");
    return Promise.resolve();
  }, []);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Print Example",
  });

  const handleDownload = useCallback(() => {
    const input = componentRef.current;
    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: [58, 210]  // Adjust this to match your POS paper size
      });
      pdf.addImage(imgData, 'PNG', 0, 0, 58, 0);
      pdf.save("Reload_" + new Date().toISOString().split('T')[0] +"_.pdf");
    });
  }, []);

  useEffect(() => {
    if (finalData.data) {
      dispatch(hideAgentIntentModal());
      dispatch(requeryCoralTxn(finalData.data.result.txMessage.systemReference))
      dispatch(txStatusQuery(finalData.data.result.txMessage.systemReference));
      // let fd = finalData.data.result ? JSON.parse(finalData.data.result.txMessage) : null;
      // let fd = finalData.data.result && finalData.data.result.txMessage
      let fd = finalData.data.result.productResult
      let val = transSuccess ? removeString(transSuccess.data.dataObject) : "";
      console.log(val)
      //   ? JSON.parse(finalData.data.result.txMessage)
      // ? finalData.data.result.txMessage
      // : null;
      let descType = intent.success ? intent.success.account.productId.productcategoryId.categoryname : ""
      let fdata = finalData.data.result.txMessage.transactionlogId;
      let amountValues = intentData ? intentData.result.amount : intent.paymentIntent ? intent.paymentIntent.result.amount : intent.walletUserInit ? intent.walletUserInit.result ? intent.walletUserInit.result.amount : intent.walletUserInit.amount : ""
      let feeData = intentData ? intentData.result.fee : intent.paymentIntent ? intent.paymentIntent.result.fee : intent.walletUserInit ? intent.walletUserInit.result ? intent.walletUserInit.result.fee : intent.walletUserInit.fee : ""
      let sumAmount = intentData ? intentData.result.totalAmount : intent.paymentIntent ? intent.paymentIntent.result.totalAmount : intent.walletUserInit ? intent.walletUserInit.result ? intent.walletUserInit.result.totalAmount : intent.walletUserInit.totalAmount : ""
      // let address = val ? val.responseData.customer.customerAddress ? val.responseData.customer.customerAddress : val.responseData.customer.address : ""
      // let customerName = val ? val.responseData.customer.customerName : ""
      let address = localStorage.getItem("address")
      let customerName = val ? val.responseData.customer.customerName : localStorage.getItem("customerName") ? localStorage.getItem("customerName") : ""
      setCustomerName(customerName)
      setTypeData(descType)
      setAddr(address)
      // setAddr(val != "" ? address : "")
      setktc1(fd.responseData.responseData.tokenData ? fd.responseData.responseData.tokenData.kct1 : fd.responseData.responseData.responseData ? fd.responseData.responseData.responseData.tokenData ? fd.responseData.responseData.responseData.tokenData.kct1 : "" : "")
      setktc2(fd.responseData.responseData.tokenData ? fd.responseData.responseData.tokenData.kct2 : fd.responseData.responseData.responseData ? fd.responseData.responseData.responseData.tokenData ? fd.responseData.responseData.responseData.tokenData.kct2 : "" : "")
      // setktc1(fd ? fd.responseData.responseData.tokenData.kct1 : "")
      // setktc2(fd ? fd.responseData.responseData.tokenData.kct2 : "")
      setTotalAmount(formatAmount(sumAmount))
      setEmail(modalSet.submit.email);
      setDescription(fdata.description);
      setAmount(formatAmount(amountValues))
      setFee(formatAmount(feeData))
      setProductLogo(fdata.productId.logourl)
      setDate(fdata.transactiondate);
      setPackageName(fd.responseData.responseData.packageName ? fd.responseData.responseData.packageName : fd.responseData.responseData.responseData ? fd.responseData.responseData.responseData.packageName : "");
      // setPackageName(fd.responseData.responseData.packageName);
      setpaymentRef(finalData.data.result.reference);
      setCustomerMessageData(fd.responseData.responseData ? fd.responseData.responseData.customerMessage : fd.responseData.responseData.responseData.customerMessage);
      // setNarration(fd.responseData.responseData.customerMessage);
      // setCustomerMessageData(fd.responseData.responseData.customerMessage ? fd.responseData.responseData.customerMessage : fd.responseData.responseData.responseData ? fd.responseData.responseData.responseData.customerMessage : "")
      console.log(fd)
      if (fd && fd.responseData && fd.responseData.responseData && fd.responseData.responseData.responseData) {
        const { customerMessage } = fd.responseData.responseData.responseData;

        console.log(customerMessage)
        // Check if customerMessage exists before setting it
        if (customerMessage) {
          setUnits(
            fd.responseData?.responseData?.tokenData?.stdToken?.units || ""
          );

          setTokenData(
            fd.responseData?.responseData?.tokenData?.stdToken?.value || ""
          );
          // setUnits(fd.responseData.responseData.tokenData ? fd.responseData.responseData.tokenData.stdToken.units : fd.responseData.responseData.responseData ? fd.responseData.responseData.responseData.tokenData.stdToken.units ? "" : "" : "")
          // setTokenData(fd.responseData.responseData.tokenData ? fd.responseData.responseData.tokenData.stdToken.value : fd.responseData.responseData.responseData ? fd.responseData.responseData.responseData.tokenData.stdToken.value ? "" : "" : "")
          setNarration(customerMessage);
        }
      }
      setStatus(fd.responseData.responseData.status ? fd.responseData.responseData.status : fd.responseData.responseData.vendStatus);
    } else {
      // window.location.href = "/"
    }
  }, [finalData]);

  useEffect(() => {
    if (requeryData && modalSet) {
      let fd = requeryData.responseData;
      let descType = intent.success ? intent.success.account.productId.productcategoryId.categoryname : requeryData.responseData.billerName
      // let fdata = finalData.data.result.productResult.transactionlogId;
      let amountValues = intentData ? intentData.result.amount : intent.paymentIntent ? intent.paymentIntent.result.amount : intent.walletUserInit ? intent.walletUserInit.result.amount : ""
      let feeData = intentData ? intentData.result.fee : intent.paymentIntent ? intent.paymentIntent.result.fee : intent.walletUserInit ? intent.walletUserInit.result.fee : ""
      let sumAmount = intentData ? intentData.result.totalAmount : intent.paymentIntent ? intent.paymentIntent.result.totalAmount : intent.walletUserInit ? intent.walletUserInit.result.totalAmount : ""
      setTypeData(descType)
      setktc1(fd.tokenData ? fd.tokenData.kct1 : "")
      setktc2(fd.tokenData ? fd.tokenData.kct2 : "")
      setTotalAmount(formatAmount(sumAmount))
      setEmail(modalSet.email);
      setDescription("Requery Successful");
      setAmount(formatAmount(amountValues))
      setFee(formatAmount(feeData))
      setProductLogo(modalSet.img)
      setDate(fd.date);
      setPackageName(fd.billerName);
      setpaymentRef(fd.paymentReference);
      setNarration(fd.customerMessage);
      setStatus(fd.status);
    }
  }, [requeryData])

  const handleBack = (e) => {
    e.preventDefault();
    localStorage.clear();
    window.location.href = `${process.env.PUBLIC_URL}/`;
  };

  function formatAmount(amount) {
    return new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(amount);
  }

  useEffect(() => {
    if (finalData.data || requeryData) {
      dispatch(WalletBalance(wallet.user ? wallet.user.accountNo : agentWallet.balance ? agentWallet.balance.data.accountNumber : ""));
      const handleBeforeUnload = () => {
        setBlocking(true);
        return "Are you sure you want to leave?";
      };

      window.addEventListener('beforeunload', handleBeforeUnload);

      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }
  }, [finalData.data, requeryData]);

  return (
    <div className="container mx-auto px-4">
      <div className="flex justify-between items-center">
        <Button onClick={handleBack} variant="outline">
          Go back
        </Button>
        <Button onClick={handleDownload} style={{ backgroundColor: "green", color: "#fff" }}>
          Download
        </Button>
      </div>
      <style jsx global>{`
        @media print {
          @page {
            size: 58mm auto;
            margin: 5mm;
          }
          body {
            font-family: Arial, sans-serif;
            font-size: 10px;
            color: #000;
          }
          .receipt-header {
            text-align: center;
            font-weight: bold;
            margin-bottom: 5mm;
          }
          .receipt-content {
            line-height: 1.2;
          }
          .receipt-footer {
            text-align: center;
            font-size: 8px;
            margin-top: 5mm;
          }
        }
      `}</style>
      <div className="bg-white p-4 rounded-lg shadow-lg max-w-2xl mx-auto" style={{ maxWidth: "35%" }} ref={componentRef}>
        <div className="text-center mb-2">
          <p className="font-bold">
            Invoice #: {tranStatus_query?.data?.reference || finalData?.data?.result?.reference || ""}
          </p>
          <p className="text-sm">
            Transaction Date: {tranStatus_query?.data ? new Date(tranStatus_query.data.transactiondate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) : tranStatus_requery?.responseData?.confirmationTime ? new Date(tranStatus_requery.responseData.confirmationTime).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) : finalData.data ? finalData.data.result.txDate : ""}
          </p>
        </div>
        <div className="text-2xl font-bold"><h3 className="text-center m-2">Reload.ng</h3></div>
        <div className="space-y-2 text-center m-2">
          <p>Email: {email || finalData.data ? finalData.data.result.txMessage.transactionlogId.email : ""}</p>
          {customerMessageData && <p>Customer message: Your {tranStatus_requery?.responseData?.billerName} payment was successful</p>}
          {tranStatus_requery && <p>Account Number: {tranStatus_requery.responseData.customer. accountNumber}</p>}

          {tranStatus_requery?.responseData?.tokenData && (
            <>
              {localStorage.getItem("address") && <p>Address: {localStorage.getItem("address")}</p>}
              {customerName && <p>Customer name: {customerName}</p>}
              <p>Token: <b>{tranStatus_requery.responseData.tokenData.stdToken.value}</b></p>
              <p>Unit: {tranStatus_requery.responseData.tokenData.stdToken.units}</p>
            </>
          )}
          <p>Status: Transaction successful</p>
          {tranStatus_requery?.responseData?.tokenData?.kct1 && tranStatus_requery.responseData.tokenData.kct2 && (
            <>
              <p>kct1: {tranStatus_requery.responseData.tokenData.kct1}</p>
              <p>kct2: {tranStatus_requery.responseData.tokenData.kct2}</p>
            </>
          )}
        </div>

        <div className="invoice-body">
          <table className="table">
            <thead>
              <tr>
                <th>Description</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {/* <td>MTN</td> */}
                <td>{tranStatus_requery?.responseData?.billerName || finalData.data ? finalData.data.result.productResult.responseData.responseData.packageName : ""}</td>
                <td>
                  ₦{tranStatus_query?.data?.amount ||
                    walletUserInit?.result?.amount ||
                    (paymentIntent?.result ? toTwoDecimal(paymentIntent.result.totalAmount) : "")}
                </td>
              </tr>
              <tr>
                <td>Convenience fee</td>
                <td>
                  ₦{tranStatus_query?.data?.fee ||
                    walletUserInit?.result?.fee ||
                    (paymentIntent?.result ? toTwoDecimal(paymentIntent.result.fee) : finalData.data ? finalData.data.result.txMessage.transactionlogId.fee : "")}
                </td>
              </tr>
            </tbody>
          </table>
          <div className="flex-table" style={{ display: "flex", justifyContent: "center" }}>
            <div className="flex-column">
              <table className="table-subtotal">
                <tbody>
                  <tr>
                    <td>Amount paid</td>
                    <td>
                      ₦{tranStatus_query?.data?.amount ||
                        walletUserInit?.result?.amount ||
                        (paymentIntent?.result ? toTwoDecimal(paymentIntent.result.totalAmount) : "")}
                    </td>
                  </tr>
                  <tr>
                    <td>Convenience fee</td>
                    <td>
                      ₦{tranStatus_query?.data?.fee ||
                        walletUserInit?.result?.fee ||
                        (paymentIntent?.result ? toTwoDecimal(paymentIntent.result.fee) : "")}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="invoice-total-amount" style={{ display: "flex", justifyContent: "center" }}>
            <p><b>
              Total: ₦{
                tranStatus_query?.data?.price ??
                walletUserInit?.result?.totalAmount ??
                (paymentIntent?.result ? toTwoDecimal(paymentIntent.result.totalAmount) : "")
              }
            </b></p>
          </div>
        </div>
        <small>********************************************************************************</small>
        <div className="text-center text-sm">
          <p>❤ Thanks for your patronage</p>
        </div>
      </div>
    </div>
  );
}