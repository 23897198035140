export const DEFAULT = "DEFAULT";
export const THROW_ERROR = "THROW_ERROR";
export const THROW_SUCCESS = "THROW_SUCCESS";
export const IS_LOADING = "IS_LOADING";
export const RE_RENDERED = "RE_RENDERED";
export const PRODUCT_LOADING = "PRODUCT_LOADING";
export const PRODUCT_LOADED = "PRODUCT_LOADED";
export const PRODUCT_FAIL = "PRODUCT_FAIL";
export const PRODUCTLIST = "PRODUCTLIST";
export const PRODUCTLIST_error = "PRODUCTLIST_error";
export const CLEAR_PRODUCTS = "CLEAR_PRODUCTS";
export const GET_PRODUCT_FAIL = "GET_PRODUCT_FAIL";
export const RANKING_LOADING = "RANKING_LOADING";
export const RANKING_LOADED = "RANKING_LOADED";
export const RANKING_FAIL = "RANKING_FAIL";
export const GET_PRODUCT_IMG = "GET_PRODUCT_IMG";
export const OPEN_MODAL = "OPEN_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";
export const SUBMIT_MODAL = "SUBMIT_MODAL";
export const VERIFY_SUCCESS = "VERIFY_SUCCESS";
export const VERIFY_FAILED = "VERIFY_FAILED";
export const VALUE_DATA = "VALUE_DATA";
export const AUTH_ERROR = "AUTH_ERROR";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGOUT_USER = "LOGOUT_USER";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";
export const CONTACTUS_ERROR = "CONTACTUS_ERROR";
export const CONTACTUS = "CONTACTUS";
export const PRODUCTNAME_DATA = "PRODUCTNAME_DATA";
export const PRODUCTNAME_ERROR = "PRODUCTNAME_ERROR";
export const PRODUCT_Id = "PRODUCT_Id";
export const PRODUCT_NAME = "PRODUCT_NAME";
export const PAYMENT_INTENT = "PAYMENT_INTENT";
export const PAYMENT_INTENT_ERROR = "PAYMENT_INTENT_ERROR";
export const FINAL_PAYMENT = "FINAL_PAYMENT";
export const FINAL_PAYMENT_ERROR = "FINAL_PAYMENT_ERROR";
export const EDITING_USER = "EDITING_USER";
export const USER_UPDATED = "USER_UPDATED";
export const UPDATED_FAILED = "UPDATED_FAILED";
export const SHOWSTATUS = "SHOWSTATUS";
export const HIDESTATUS = "HIDESTATUS";
export const IS_CHANGING_PASSWORD = "IS_CHANGING_PASSWORD";
export const PASSWORD_CHANGED = "PASSWORD_CHANGED";
export const PASSWORD_CHANGE_FAILED = "PASSWORD_CHANGE_FAILED";
export const RESET_PASSWORD = "RESET_PASSWORD"
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL"
export const UPDATE_PROFILE = "UPDATE_PROFILE"
export const UPDATE_PROFILE_ERROR = "UPDATE_PROFILE_ERROR"
export const WALLET_FUNDING = "WALLET_FUNDING"
export const WALLET_FUNDING_ERROR = "WALLET_FUNDING_ERROR"
export const WALLET_BALANCE_LOADING = "WALLET_BALANCE_LOADING"
export const WALLET_BALANCE_LOADED = "WALLET_BALANCE_LOADED"
export const WALLET_BALANCE_ERROR = "WALLET_BALANCE_ERROR"
export const INTENT_SUCCESS = "INTENT_SUCCESS"
export const INTENT_ERROR = "INTENT_ERROR"
export const VERIFY_WALLET_FUNDING = "VERIFY_WALLET_FUNDING"
export const VERIFY_WALLET_FUNDING_ERROR = "VERIFY_WALLET_FUNDING_ERROR"
export const FUND = "FUND"
export const FUND_ERROR = "FUND_ERROR"
export const USER_ID = "USER_ID"
export const USER_ID_ERROR = "USER_ID_ERROR"
export const SELECT_PRODUCTS = "SELECT_PRODUCTS"
export const AGENT_REGISTER = "AGENT_REGISTER"
export const AGENT_REGERROR = "AGENT_REGERROR"
export const AGENT_LOGIN = "AGENT_LOGIN"
export const AGENT_LOGERROR = "AGENT_LOGERROR"
export const USER_AGENT_LOADING = "USER_AGENT_LOADING"
export const USER_AGENT_LOADED = "USER_AGENT_LOADED"
export const AUTH_AGENT_ERROR = "AUTH_AGENT_ERROR"
export const WALLET_AGENT_FUNDING = "WALLET_AGENT_FUNDING"
export const WALLET_AGENT_FUNDING_ERROR = "WALLET_AGENT_FUNDING_ERROR"
export const WALLET_AGENT_FUNDED = "WALLET_AGENT_FUNDED"
export const WALLET_AGENT_FUNDED_ERROR = "WALLET_AGENT_FUNDED_ERROR"
export const GET_AGENT_BALANCE = "GET_AGENT_BALANCE"
export const GET_AGENT_BALANCE_ERROR = "GET_AGENT_BALANCE_ERROR"
export const AGENT_ID = "AGENT_ID"
export const AGENT_ID_ERROR = "AGENT_ID_ERROR"
export const REQUERY = "REQUERY"
export const REQUERY_ERROR = "REQUERY_ERROR"
export const WALLET_PAYMENT_INIT = "WALLET_PAYMENT_INIT"
export const WALLET_INIT_PAYMENT_ERROR = "WALLET_INIT_PAYMENT_ERROR"
export const WALLET_PAYMENT_FINAL = "WALLET_PAYMENT_FINAL"
export const WALLET_FINAL_ERROR = "WALLET_FINAL_ERROR"
export const AGENT_TXN = "AGENT_TXN"
export const AGENT_TXN_ERR = "AGENT_TXN_ERR"
export const OPEN_AGENT_MODAL = "OPEN_AGENT_MODAL"
export const HIDE_AGENT_MODAL = "HIDE_AGENT_MODAL"
export const NON_USER_PAYMENT_INTENT = "NON_USER_PAYMENT_INTENT"
export const NON_USER_PAYMENT_INTENT_ERROR = "NON_USER_PAYMENT_INTENT_ERROR"
export const SUBSCRIBERS = "SUBSCRIBERS"
export const SUBSCRIBERS_ERROR = "SUBSCRIBERS_ERROR"
export const INVOKE_CORALPAY = "INVOKE_CORALPAY"
export const INVOKE_CORALPAY_ERROR = "INVOKE_CORALPAY_ERROR"
export const TxQUERY_CORALPAY = "TxQUERY_CORALPAY"
export const TxQUERY_CORALPAY_ERROR = "TxQUERY_CORALPAY_ERROR"
export const TXSTATUS_QUERY = "TXSTATUS_QUERY"
export const TXSTATUS_QUERY_ERROR = "TXSTATUS_QUERY_ERROR"
export const TX_REQUERY = "TX_REQUERY"
export const TX_REQUERYERR = "TX_REQUERYERR"
export const CLEAR_SUBMIT_MODAL = "CLEAR_SUBMIT_MODAL"
export const ACCOUNT_QUERY = "ACCOUNT_QUERY"
export const ACCOUNT_QUERY_ERROR = "ACCOUNT_QUERY_ERROR"
export const AGENTPAY_ID = "AGENTPAY_ID"
export const AGENTPAY_ID_ERROR = "AGENTPAY_ID_ERROR"
export const USERPAY_ID = "USERPAY_ID"
export const USERPAY_ID_ERROR = "USERPAY_ID_ERROR"