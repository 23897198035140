import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { agentTransaction, userTransaction } from '../../store/actions/transaction';
import PaginationComponent from '../../components/Pagination';
import { agentPayment, userPayment } from '../../store/actions/agentWallet';
import Moment from 'react-moment';

export default function FundHistory() {
    const dispatch = useDispatch();
    const agentTxn = useSelector((state) => state.trans);
    const user = useSelector((state) => state.authUser.user);
    const agentData = useSelector((state) => state.agentFunding.agentpayData);
    const userData = useSelector((state) => state.agentFunding.userpay);

    const [activePage, setActivePage] = useState(1);
    const itemsPerPage = 5; // Number of items to display per page

    useEffect(() => {
        if (localStorage.getItem("agent_token")) {
            dispatch(agentPayment(localStorage.getItem('agentId')));
        }
    }, [localStorage.getItem("agent_token")]);

    useEffect(() => {
        if (localStorage.getItem("token")) {
            dispatch(userPayment(localStorage.getItem("userId")));
        }
    }, [localStorage.getItem("token")]);

    // Calculate the total number of pages
    const totalItems = agentData || userData ? agentData.length || userData.length : 0;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    // Calculate the range of items to display on the current page
    const startIndex = (activePage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const handlePageChange = (pageNumber) => {
        // console.log(pageNumber)
        setActivePage(pageNumber);
    };

    return (
        <div className='container'>
            <div className='cardTxn'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>s/no</th>
                            <th>Email</th>
                            <th>Description</th>
                            <th>System reference</th>
                            <th>Status</th>
                            <th>Amount</th>
                            <th>date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {agentData ? agentData &&
                            agentData
                                .slice(startIndex, endIndex)
                                .map((allData, i) => (
                                    <tr key={i}>
                                        <td>{i + 1}</td>
                                        <td>{allData.agent ? allData.agent.email : allData.userId.email}</td>
                                        <td>{allData.narration}</td>
                                        <td>{allData.reference}</td>
                                        <td>{allData.status == "P" ? "Funding pending" : allData.status ? "Successfully funded" : "failed funding"}</td>
                                        <td>{allData.amount}</td>
                                        <td><Moment unix>{Math.floor(allData.created / 1000)}</Moment></td>
                                    </tr>
                                )) : null}

                        {userData ? userData &&
                            userData
                                .slice(startIndex, endIndex)
                                .map((allData, i) => (
                                    <tr key={i}>
                                        <td>{i + 1}</td>
                                        <td>{allData.agent ? allData.agent.email : allData.userId.email}</td>
                                        <td>{allData.narration}</td>
                                        <td>{allData.reference}</td>
                                        <td>{allData.status == "P" ? "Funding pending" : allData.status ? "Successfully funded" : "failed funding"}</td>
                                        <td>{allData.amount}</td>
                                        <td><Moment unix>{Math.floor(allData.created / 1000)}</Moment></td>
                                    </tr>
                                )) : null}
                    </tbody>
                </table>
                <PaginationComponent
                    noDown
                    itemPerPage={itemsPerPage}
                    totalItems={totalItems}
                    paginate={handlePageChange}
                    currentPage={activePage}
                />
                {/* <Pagination
          activePage={activePage}
          itemsCountPerPage={itemsPerPage}
          totalItemsCount={totalItems}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
          // itemClass='page-item'
          // linkClass='page-link'
        /> */}
            </div>
        </div>
    );
}
