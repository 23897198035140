import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import PrivateRoute from "../_PrivateRoute";
import HomePage from "../HomePage";
import BusinessPage from "../BusinessPage";
import ContactUsPage from "../ContactUsPage";
import NotFoundPage from "../NotFoundPage";
import AboutUsThreePage from "../AboutUsThreePage";
import Receipt from "../Receipt";
// import { useDispatch } from "react-redux";
import AgentHome from "../AgentHome"
import PaymentFailed from "../../components/DefaultModal/PaymentFailed";
import AgentReset from "../../components/PostGallery/AgentReset";
import UserHome from "../AgentHome/UserHome";
import Terms from "../../components/Terms&Condition";
import Faq from "../Faq";
import Privacy from "../../components/Terms&Condition/Privacy";
import Subscribers from "../../pages/Subscribers";
import ResetPassword from "../../components/PostGallery/resetPassword";
import Processing from "../../components/WidgetTab/Processing";
import CoralReceipt from "../Receipt/CoralReceipt";
import FundHistory from "../AgentHome/FundHistory";

const Routes = () => {
  return (
    <Switch>
      {/*home one routes*/}
      <Redirect exact from="/" to={`${process.env.PUBLIC_URL}/`} />
      <PrivateRoute exact path={`${process.env.PUBLIC_URL}/`} parentClass="theme-3" component={HomePage} />
      <PrivateRoute exact path={`${process.env.PUBLIC_URL}/terms`} parentClass="theme-3" component={Terms} />
      <PrivateRoute exact path={`${process.env.PUBLIC_URL}/unsubscriber`} parentClass="theme-3" component={Subscribers} />
      <PrivateRoute exact path={`${process.env.PUBLIC_URL}/privacy`} parentClass="theme-3" component={Privacy} />
      <PrivateRoute exact path={`${process.env.PUBLIC_URL}/faq`} parentClass="theme-3" component={Faq} />
      <PrivateRoute exact path={`${process.env.PUBLIC_URL}/user/resetpassword`} parentClass="theme-3" component={ResetPassword} />
      <PrivateRoute exact path={`${process.env.PUBLIC_URL}/payment-failed/:reference`} parentClass="theme-3" component={PaymentFailed} />
      <PrivateRoute exact path={`${process.env.PUBLIC_URL}/processing/:reference`} parentClass="theme-3" component={Processing} />
      <PrivateRoute exact path={`${process.env.PUBLIC_URL}/processing`} parentClass="theme-3" component={Processing} />
      <PrivateRoute
        exact
        path={`${process.env.PUBLIC_URL}/stat/receipt/:reference`}
        parentClass="theme-3"
        component={CoralReceipt}
      />
      <PrivateRoute
        exact
        path={`${process.env.PUBLIC_URL}/receipt`}
        parentClass="theme-3"
        component={Receipt}
      />
      <PrivateRoute
        exact
        path={`${process.env.PUBLIC_URL}/agent`}
        parentClass="theme-3"
        component={AgentHome}
      />
      <PrivateRoute
        exact
        path={`${process.env.PUBLIC_URL}/user`}
        parentClass="theme-3"
        component={UserHome}
      />
      <PrivateRoute
        exact
        path={`${process.env.PUBLIC_URL}/funding/history`}
        parentClass="theme-3"
        component={FundHistory}
      />
      <PrivateRoute
        exact
        path={`${process.env.PUBLIC_URL}/resetpassword`}
        parentClass="theme-3"
        component={AgentReset}
      />
      <PrivateRoute
        exact
        path={`${process.env.PUBLIC_URL}/business`}
        parentClass="theme-3"
        component={BusinessPage}
      />
      <PrivateRoute
        exact
        path={`${process.env.PUBLIC_URL}/contact`}
        parentClass="theme-3"
        component={ContactUsPage}
      />
      <PrivateRoute
        exact
        home_style={3}
        parentClass="theme-3"
        path={`${process.env.PUBLIC_URL}/about`}
        component={AboutUsThreePage}
      />
      <PrivateRoute
        exact
        path={`${process.env.PUBLIC_URL}/404`}
        parentClass="theme-1"
        component={NotFoundPage}
      />



      <Route exact component={NotFoundPage} />
    </Switch>
  );
};
export default Routes;
