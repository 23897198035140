// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBx4wOhK0pZ_HNjUTocgz2KiqnyAWVewhY",
  authDomain: "reloadblueprint-a7b11.firebaseapp.com",
  projectId: "reloadblueprint-a7b11",
  storageBucket: "reloadblueprint-a7b11.firebasestorage.app",
  messagingSenderId: "41547846879",
  appId: "1:41547846879:web:dd5cc994094b42cba6456a",
  measurementId: "G-2BB4JGSNHE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app)
const auth = getAuth(app);

export {app, auth}
