import { AGENTPAY_ID, AGENTPAY_ID_ERROR, GET_AGENT_BALANCE, GET_AGENT_BALANCE_ERROR, USERPAY_ID, USERPAY_ID_ERROR, WALLET_AGENT_FUNDING, WALLET_AGENT_FUNDING_ERROR } from "../constants";

const initialState = {
	walletFunding: null,
	fundingError: null,
	balance: null,
	balanceError: null,
	agentpayData: [],
	agentPay_Err: null,
	userpay: [],
	userpayErr: null
};

const agentFunding = (state = initialState, action) => {
	switch (action.type) {
		case WALLET_AGENT_FUNDING:
			return {
				...state,
				walletFunding: action.payload,
			};
		case WALLET_AGENT_FUNDING_ERROR:
			return {
				...state,
				fundingError: true
			}
		case GET_AGENT_BALANCE:
			return {
				...state,
				balance: action.payload
			}
		case GET_AGENT_BALANCE_ERROR:
			return {
				...state,
				balanceError: action.payload
			}
		case AGENTPAY_ID:
			return {
				...state,
				agentpayData: action.payload
			}
		case AGENTPAY_ID_ERROR:
			return {
				...state,
				agentPay_Err: action.payload
			}
		case USERPAY_ID:
			return {
				...state,
				userpay: action.payload
			}
		case USERPAY_ID_ERROR:
			return {
				...state,
				userpayErr: action.payload
			}
		default:
			return state;
	}
};

export default agentFunding;
