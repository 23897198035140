import React, { useEffect, useState } from "react";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { Button } from "reactstrap";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import google from "../images/social/google.png";
import { handleGoogle } from "../store/actions/userAction";
import { doSignInWithGoogle } from "../firebase/auth";
import { useHistory } from "react-router-dom"

const GoogleLoginButton = () => {
  const dispatch = useDispatch();
  const history = useHistory()
  const userdata = useSelector(state => state.authUser.user);

  const onGoogleSignIn = (e) => {
    e.preventDefault()
    if (!userdata) {
      doSignInWithGoogle().then(res => {
        const data = {
          access_token: res.user.accessToken
        }

        dispatch(handleGoogle(data));
      }).catch(err => console.log(err))
    }
  }

  useEffect(() => {
    if (userdata) {
      history.push(`${process.env.PUBLIC_URL}/user`)
    }
  }, [userdata]);

  return (
    <Button style={{ background: "#fff", color: "#000" }} onClick={onGoogleSignIn}>
      <img src={google} width={20} alt="..." /> Login with Google
    </Button>
  );
};

export default GoogleLoginButton;
