import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { clearSubmitModal, hideAgentIntentModal, hideModal, openAgentIntentModal, submitModal } from "../../store/actions/modalSetup";
import { FormStructure } from "../../utils/FormStructure";
import {
  PaymentAgentIntent,
  PaymentIntent,
  UserWalletPaymentFinal,
  UserWalletPaymentInit,
  verifySmartcardNumber,
} from "../../store/actions/transaction";
import { useHistory } from "react-router-dom"
import { CircleLoader } from "react-spinners";
import { finalAgentPayment, finalPayment } from "../../store/actions/finalPayment";
import { hideLoader } from "../../store/actions";
import { toast } from "react-toastify";
import { invokeCoralPay, txStatusQuery } from "../../store/actions/Coralpay";
import { closePaymentModal, useFlutterwave } from "flutterwave-react-v3";

export default function DefaultModal() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false)
  const modal = useSelector((state) => state.modalSet);
  const verify = useSelector((state) => state.modalSet.verify);
  const transSuccess = useSelector((state) => state.trans.success);
  const modalSet = useSelector((state) => state.modalSet);
  const transSu = useSelector((state) => state.trans.paymentIntent);
  const nonUserIntentDetails = useSelector((state) => state.trans.nonUserIntent);
  const walletUser = useSelector((state) => state.trans.walletUserInit);
  const wallet = useSelector(state => state.wallet.loaded)
  const agentWallet = useSelector(state => state.agentFunding.balance)
  const productData = useSelector(state => state.product.selectPro)
  const verifyError = useSelector(state => state.trans.error)
  const finaliseErr = useSelector(state => state.finalPayment.err)
  const [fund, setFund] = useState(false)
  const intentData = useSelector((state) => state.trans.paymentIntent);
  const finalPaymentData = useSelector(state => state.finalPayment)
  const paymentIntentErr = useSelector(state => state.trans.paymentIntentError)
  const invokePayment = useSelector(state => state.invokeCoralpay.invoke)
  const userWalletIntentErr = useSelector(state => state.trans.walletUserInitErr)

  useEffect(() => {
    if (nonUserIntentDetails && invokePayment) {
      setLoader(false)
      dispatch(submitModal("open", false));
      // setO
    }
  }, [nonUserIntentDetails && invokePayment])

  // const handleFlutterPayment = useFlutterwave({
  //   public_key: `${process.env.REACT_APP_FLUTTERWAVE_PUBLIC_KEY}`,
  //   tx_ref: intentData ? intentData.transRef : nonUserIntentDetails ? nonUserIntentDetails.transRef : "",
  //   amount: intentData ? intentData.result.totalAmount : nonUserIntentDetails ? nonUserIntentDetails.result.totalAmount : "",
  //   currency: "NGN",
  //   payment_options: "card",
  //   trackingNo: intentData ? intentData.transRef : nonUserIntentDetails ? nonUserIntentDetails.transRef : "",
  //   customer: {
  //     email: modalSet && modalSet.submit ? modalSet.submit.email : "",
  //     name: modalSet && modalSet.submit ? modalSet.submit.accountName : "",
  //   },
  //   customizations: {
  //     title: "Reload.ng",
  //     description: "Make all Bill Payments",
  //     logo:
  //       "https://blacksiliconimages.s3.us-west-2.amazonaws.com/Reload.ng/Reload.jpeg",
  //   },
  // });

  // useEffect(() => {
  //   if (fund === true) {
  //     handleFlutterPayment({
  //       callback: (response) => {
  //         const ref = {
  //           // transRef: "bp02312070004623",
  //           transRef: intentData ? intentData.transRef : nonUserIntentDetails ? nonUserIntentDetails.transRef : "",
  //           paymentRef: response.transaction_id,
  //           accountNumber: modalSet.submit.accountNumber ? modalSet.submit.accountNumber : modalSet.submit.phoneNumber,
  //         };

  //         console.log(ref);

  //         dispatch(finalPayment(ref));
  //         // dispatch(showLoader());
  //         setTimeout(() => {
  //           closePaymentModal();
  //         }, 2000);
  //       },
  //       onClose: () => {
  //         closePaymentModal();
  //       },
  //     });
  //   }
  // }, [fund]);

  useEffect(() => {
    if (nonUserIntentDetails) {
      if (modal.submit.email && modal.submit.amount && modal.submit.paymentGate === "coralpay") {
        console.log(nonUserIntentDetails)
        const data = {
          email: modal.submit.email,
          // amount: modal.submit.amount,
          amount: nonUserIntentDetails.result.totalAmount,
          reference: nonUserIntentDetails.transRef,
          productId: modal.productId
        }

        // console.log(data)
        // alert("working")
        dispatch(invokeCoralPay(data))
      } else if (localStorage.getItem("amount") && modal.submit.email && modal.submit.paymentGate === "coralpay") {
        console.log(nonUserIntentDetails)
        const data = {
          email: modal.submit.email,
          // amount: modal.submit.amount,
          amount: nonUserIntentDetails.result.totalAmount,
          reference: nonUserIntentDetails.transRef,
          productId: modal.productId
        }

        // console.log(data)
        // alert("working")
        dispatch(invokeCoralPay(data))
      } else if (modal.submit.email && modal.submit.amount && modal.submit.paymentGate === "flutterwave") {
        setFund(true)
      }
      // alert("working")
      // dispatch(submitModal("paymentGate", "coralpay"));
    }
  }, [nonUserIntentDetails])

  useEffect(() => {
    if (finalPaymentData.data) {
      dispatch(hideAgentIntentModal());
      // setOpen(false)
      setTimeout(() => {
        setLoader(false)
        toast.success("Transaction successful")
        dispatch(hideLoader());
      }, 3000);
      history.push(`${process.env.PUBLIC_URL}/receipt`)
    }
  }, [finalPaymentData.data])

  let d = transSuccess
    ? "Initialize payment"
    : verify
      ? "Verify details"
      : "Initialize payment";

  useEffect(() => {
    if (transSuccess) {
      setLoader(false);
      const email = localStorage.getItem("email") ? localStorage.getItem("email") : ""
      const phoneNumber = localStorage.getItem("phoneNumber") ? localStorage.getItem("phoneNumber") : ""
      dispatch(submitModal("email", email));
      dispatch(submitModal("phoneNumber", phoneNumber));
    }
  }, [transSuccess]);

  useEffect(() => {
    if (transSu || walletUser) {
      setLoader(false);
      dispatch(hideModal())
      // setOpen(true)
      dispatch(openAgentIntentModal())
    }
  }, [transSu || walletUser]);

  useEffect(() => {
    if (verifyError) {
      setLoader(false)
    }
  }, [verifyError])

  useEffect(() => {
    if (paymentIntentErr) {
      setLoader(false)
      toast.error(paymentIntentErr.data.message)
    }
  }, [paymentIntentErr || userWalletIntentErr])

  useEffect(() => {
    if (userWalletIntentErr) {
      setLoader(false)
      toast.error(userWalletIntentErr.data.message)
    }
  }, [userWalletIntentErr])

  const submitModalFunc = (e) => {
    e.preventDefault();
    setLoader(true);

    // console.log(modal)
    // if(modal.s)
    if (!transSuccess && verify) {
      const details = {
        product: modal.submit.product,
        billerCode: modal.submit.slug,
        accountNumber: modal.submit.customerId,
        extras: {
          billerSlug: productData.productId.productcategoryId.categoryname === "Electricity" ? modal.submit.productName : modal.submit.productName,
          customerId: modal.submit.customerId,
          productName: modal.submit.productName,
        },
      };

      console.log(productData)
      dispatch(verifySmartcardNumber(details));

      // if(transSuccess && transSuccess.account.extras === "Startimes") {
    } else {
      if (productData.productId.productcategoryId.categoryname === "Electricity") {
        const d = JSON.parse(transSuccess.data.dataObject)
        console.log(d);

        if (parseInt(modal.submit.amount) >= 50) {
          const data = {
            productId: modal.productId,
            amount: modal.submit.amount,
            email: modal.submit.email,
            paymentMethod: "billpayflutter",
            description: productData.productId.description,
            channelRef: "web",
            references: [
              "Account Name",
              "Account Number",
              "Customer details",
              "customerName",
              "email",
              "packageSlug",
              "phoneNumber",
            ],
            referenceValues: {
              "Account Name": modal.submit.accountName ? modal.submit.accountName : "reload",
              "Account Number": modal.submit.accountNumber ? modal.submit.accountNumber : modal.submit.phoneNumber,
              "Customer details": modal.submit.accountName ? modal.submit.accountName : "roload",
              customerName: modal.submit.accountName ? modal.submit.accountName : "reload",
              email: modal.submit.email,
              packageSlug: modal.productName ||
                (productData.productId.productname === "Mtn-VTU" ? "MTN_VTU" :
                  (productData.productId.productname === "Aitel-VTU" ? "AIRTEL_VTU" :
                    (productData.productId.productname === "glo-VTU" ? "GLO_VTU" :
                      "9mobile-VTU"))),
              phoneNumber: modal.submit.phoneNumber,
            },
          };

          dispatch(submitModal("paymentGate", "flutterwave"));
          dispatch(PaymentIntent(data))
        } else {
          setLoader(false)
          toast.error("Error: Your minimum payable amount is " + 50)
        }
      } else {
        // const d = JSON.parse(transSuccess.data.dataObject)
        // console.log(d);
        // console.log(modal)
        if (parseInt(modal.submit.amount) >= 50) {
          if (localStorage.getItem("amount") || modal.submit.amount && modal.submit.email) {
            const data = {
              productId: modal.productId,
              amount: modal.submit.amount ? modal.submit.amount : localStorage.getItem("amount"),
              email: modal.submit.email,
              paymentMethod: "billpayflutter",
              description: productData.productId.description,
              channelRef: "web",
              references: [
                "Account Name",
                "Account Number",
                "Customer details",
                "customerName",
                "email",
                "packageSlug",
                "phoneNumber",
              ],
              referenceValues: {
                "Account Name": modal.submit.accountName ? modal.submit.accountName : "reload",
                "Account Number": modal.submit.accountNumber ? modal.submit.accountNumber : modal.submit.phoneNumber,
                "Customer details": modal.submit.accountName ? modal.submit.accountName : "roload",
                customerName: modal.submit.accountName ? modal.submit.accountName : "reload",
                email: modal.submit.email,
                packageSlug: modal.productName ||
                  (productData.productId.productname === "Mtn-VTU" ? "MTN_VTU" :
                    (productData.productId.productname === "Aitel-VTU" ? "AIRTEL_VTU" :
                      (productData.productId.productname === "glo-VTU" ? "GLO_VTU" :
                        "9mobile-VTU"))),
                phoneNumber: modal.submit.phoneNumber,
              },
            };

            dispatch(submitModal("paymentGate", "flutterwave"));
            dispatch(PaymentIntent(data))
          } else {
            setLoader(false)
            toast.error("Kindly provide values for all fields.")
          }
        } else {
          setLoader(false)
          toast.error("Error: Your minimum payable amount is " + 50)
        }
      }

      // !localStorage.getItem("token") && !localStorage.getItem("agent_token") ? dispatch(PaymentIntent(data)) : localStorage.getItem("token") ? dispatch(UserWalletPaymentInit(data)) : dispatch(PaymentAgentIntent(data))
    }
  };

  const submitModalWallet = (e) => {
    e.preventDefault();
    setLoader(true);

    // console.log(modal)
    if (!transSuccess && verify) {
      const details = {
        product: modal.submit.product,
        billerCode: modal.submit.slug,
        accountNumber: modal.submit.customerId,
        extras: {
          billerSlug: productData.productId.productcategoryId.categoryname === "Electricity" ? modal.submit.productName : modal.submit.productName,
          customerId: modal.submit.customerId,
          productName: modal.submit.productName,
        },
      };

      // console.log(productData)
      dispatch(verifySmartcardNumber(details));
    } else {
      // console.log(modal.submit);
      if (wallet) {
        // const d = JSON.parse(transSuccess.data.dataObject)
        // console.log(d);

        if (parseInt(modal.submit.amount) >= 50 || parseInt(localStorage.getItem("amount")) >= 50) {
          if (parseFloat(wallet.data.currentBalance) >= parseFloat(modal.submit.amount) || parseFloat(wallet.data.currentBalance) >= parseFloat(modal.submit.amount) || parseFloat(wallet.data.currentBalance) >= parseFloat(localStorage.getItem("amount"))) {
            const data = {
              productId: modal.productId,
              amount: modal.submit.amount ? modal.submit.amount : localStorage.getItem("amount"),
              email: modal.submit.email,
              paymentMethod: "billpaywallet",
              description: productData.productId.description,
              channelRef: "web",
              references: [
                "Account Name",
                "Account Number",
                "Customer details",
                "customerName",
                "email",
                "packageSlug",
                "phoneNumber",
              ],
              referenceValues: {
                "Account Name": modal.submit.accountName ? modal.submit.accountName : "reload",
                "Account Number": modal.submit.accountNumber ? modal.submit.accountNumber : modal.submit.phoneNumber,
                "Customer details": modal.submit.accountName ? modal.submit.accountName : "roload",
                customerName: modal.submit.accountName ? modal.submit.accountName : "reload",
                email: modal.submit.email,
                packageSlug: modal.productName ||
                  (productData.productId.productname === "Mtn-VTU" ? "MTN_VTU" :
                    (productData.productId.productname === "Aitel-VTU" ? "AIRTEL_VTU" :
                      (productData.productId.productname === "glo-VTU" ? "GLO_VTU" :
                        "9mobile-VTU"))),
                phoneNumber: modal.submit.phoneNumber,
              },
            };

            // alert("initialised")
            // console.log(data)
            dispatch(UserWalletPaymentInit(data))
          } else {
            setTimeout(() => {
              console.log("not working")
              setLoader(false);
              toast.error("Invalid amount passed, wallet amount low.")
            }, 3000);
          }
        } else {
          setLoader(false)
          toast.error("Error: Your minimum payable amount is " + 50)
        }
      }

      if (agentWallet) {
        // const d = transSuccess.data ? JSON.parse(transSuccess.data.dataObject) : {}
        // console.log(d);

        if (parseInt(modal.submit.amount) >= 50 || parseInt(localStorage.getItem("amount")) >= 50) {
          const amount = parseFloat(modal.submit.amount);
          const currentBalance = agentWallet ? parseFloat(agentWallet.data.currentBalance) : wallet ? parseFloat(agentWallet.data.currentBalance) : 0;

          console.log(currentBalance);
          console.log(localStorage.getItem("amount"));

          if (currentBalance >= parseInt(localStorage.getItem("amount")) || currentBalance >= parseInt(amount)) {
            // if (agentWallet && !isNaN(amount) && !isNaN(currentBalance) || wallet && parseFloat(wallet.data.currentBalance) >= parseInt(localStorage.getItem("amount"))) {
            // if (currentBalance >= amount) {
            const agentData = {
              amount: amount ? amount : localStorage.getItem("amount"),
              description: productData.productId.description,
              paymentMethod: "billpaywallet",
              productId: modal.productId,
              email: modal.submit.email,
              customerId: modal.submit.accountNumber || modal.submit.phoneNumber,
              // packageSlug,
              packageSlug: modal.productName ||
                (productData.productId.productname === "Mtn-VTU" ? "MTN_VTU" :
                  (productData.productId.productname === "Airtel-VTU" ? "AIRTEL_VTU" :
                    (productData.productId.productname === "Glo-VTU" ? "GLO_VTU" :
                      "9mobile-VTU"))),
              customerName: modal.submit.accountName || "reload"
            };

            dispatch(PaymentAgentIntent(agentData));
            // }
          } else {
            setLoader(false);
            toast.error("Invalid amount passed, wallet amount low.")
          }
        } else {
          setLoader(false)
          toast.error("Error: Your minimum payable amount is " + 50)
        }
      }

    }
  };

  const handleCoralpay = (e) => {
    e.preventDefault()

    setLoader(true)
    const d = transSuccess ? transSuccess.data ? transSuccess.data.dataObject ? JSON.parse(transSuccess.data.dataObject) : {} : {} : {}
    const length = Array.isArray(d) ? d.length : Object.keys(d).length;
    console.log(d);

    if (parseInt(modal.submit.amount) >= 50 || parseInt(localStorage.getItem("amount")) >= 50) {
      if (modal.submit.amount || modal.submit.email) {
        const data = {
          productId: modal.productId,
          amount: modal.submit.amount ? modal.submit.amount : localStorage.getItem("amount"),
          email: modal.submit.email,
          paymentMethod: "billpayCoralpay",
          description: productData.productId.description,
          channelRef: "web",
          references: [
            "Account Name",
            "Account Number",
            "Customer details",
            "customerName",
            "email",
            "packageSlug",
            "phoneNumber",
            "address"
          ],
          referenceValues: {
            "Account Name": modal.submit.accountName ? modal.submit.accountName : "reload",
            "Account Number": modal.submit.accountNumber ? modal.submit.accountNumber : modal.submit.phoneNumber,
            "Customer details": modal.submit.accountName ? modal.submit.accountName : "roload",
            customerName: modal.submit.accountName ? modal.submit.accountName : "reload",
            email: modal.submit.email,
            packageSlug: modal.productName ||
              (productData.productId.productname === "Mtn-VTU" ? "MTN_VTU" :
                (productData.productId.productname === "Airtel-VTU" ? "AIRTEL_VTU" :
                  (productData.productId.productname === "Glo-VTU" ? "GLO_VTU" :
                    "9mobile-VTU"))),
            phoneNumber: modal.submit.phoneNumber,
            address: length > 0 ? d.responseData.customer ? d.responseData.customer.address : "" : ""
          },
        };

        dispatch(submitModal("paymentGate", "coralpay"));
        dispatch(PaymentIntent(data))
      } else {
        setTimeout(() => {
          setLoader(false)
          toast.error("Kindly provide values for all fields.")
        }, 3000);
      }
    } else {
      setLoader(false)
      toast.error("Error: Your minimum payable amount is " + 50)
    }

    // alert("working")
  }

  const submitFinal = (e) => {
    e.preventDefault();

    // console.log(wallet.data.currentBalance)
    if (transSu && transSu.result || wallet && wallet.data || agentWallet && agentWallet.data) {
      // if (parseFloat(transSu.result.totalAmount) >= parseFloat(wallet.data.currentBalance)) {
      const r = modal.submit.accountNumber ? modal.submit.accountNumber : modal.submit.phoneNumber
      let ref = transSu ? transSu.transRef : walletUser ? walletUser.transRef : "";
      setLoader(true)
      const data = {
        transRef: ref,
        // accountNumber: localStorage.getItem("customerId")
        accountNumber: r
      }

      console.log(data)
      !localStorage.getItem("token") && !localStorage.getItem("agent_token") ? dispatch(finalPayment(data)) : localStorage.getItem("token") ? dispatch(UserWalletPaymentFinal(data)) : dispatch(finalAgentPayment(data))
      // dispatch(finalAgentPayment(data))
      // } else if (parseFloat(modal.submit.amount) >= parseFloat(agentWallet.data.currentBalance)) {
      //   setLoader(false)
      //   toast.error("Error: Main balance " + wallet.data.currentBalance || agentWallet.data.currentBalance + " please sund account.")
      // }
    }
  }

  useEffect(() => {
    if (finaliseErr) {
      console.log(finaliseErr)
      setLoader(false)
      // dispatch(hideModal())
      // setOpen(false)
      dispatch(hideAgentIntentModal())
      // history.push(`${process.env.PUBLIC_URL}/payment-failed`)
      let ref = transSu ? transSu.transRef : walletUser ? walletUser.transRef : ""
      history.push(`${process.env.PUBLIC_URL}/processing/${ref}`)
    }
  }, [finaliseErr])

  return (
    <div>
      <Modal isOpen={modal.open}>
        <ModalHeader>{d}</ModalHeader>
        <ModalBody>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img width={130} src={`${modal.img}`} alt={`Image`} />
          </div>
          <FormStructure />
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={(e) => {
              e.preventDefault();
              dispatch(clearSubmitModal())
              localStorage.removeItem("amount")
              dispatch(hideModal());
              setLoader(false);
            }}
          >
            Cancel
          </Button>
          {loader ? (
            <Button disabled={true}>
              <CircleLoader size={30} color="#2E0968" />
            </Button>
          ) : (
            <>
              {
                !localStorage.getItem("token") && !localStorage.getItem("agent_token") ?
                  <>{loader ? <Button disabled={true}>
                    <CircleLoader size={30} color="#2E0968" />
                  </Button> : transSuccess ?
                    <>
                      <Button style={{
                        background: "#fda94f",
                        borderRadius: "1px solid #fda94f",
                        color: "#000",
                      }}
                        onClick={handleCoralpay}>Pay with Coralpay</Button>
                    </> : modal.verify ? <Button style={{
                      background: "#fda94f",
                      borderRadius: "1px solid #fda94f",
                      color: "#000",
                    }}
                      onClick={submitModalFunc}>Verify</Button> : <>
                      <Button style={{
                        background: "#fda94f",
                        borderRadius: "1px solid #fda94f",
                        color: "#000",
                      }}
                        onClick={handleCoralpay}>Pay with Coralpay</Button>
                    </>}
                  </>
                  : <Button
                    style={{
                      background: "#fda94f",
                      borderRadius: "1px solid #fda94f",
                      color: "#000",
                    }}
                    onClick={submitModalWallet}
                  >
                    {transSuccess ? "Submit" : modal.verify ? "Verify" : "Pay with Wallet"}
                  </Button>
              }
            </>
          )}
        </ModalFooter>
      </Modal>
      <Modal isOpen={modal.openAgentIntent}>
        <ModalHeader>{d}</ModalHeader>
        <ModalBody>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img width={130} src={`${modal.img}`} alt={`Image`} />
          </div>

          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ marginRight: "20px" }}>
              <p><b>Email: </b></p>
              <p><b>Phone Number: </b></p>
              <p><b>Amount: </b></p>
              <p><b>Fee: </b></p>
              <p><b>Total: </b></p>
            </div>
            <div>
              <p>{modal.submit ? modal.submit.email : ""}</p>
              <p>{modal.submit ? modal.submit.phoneNumber : ""}</p>
              <p>{transSu ? transSu.result.amount : walletUser ? walletUser.result ? walletUser.result.amount : "" : ""}</p>
              <p>{transSu ? transSu.result.fee : walletUser ? walletUser.result ? walletUser.result.fee : "" : ""}</p>
              <p>{transSu ? transSu.result.totalAmount : walletUser ? walletUser.result ? walletUser.result.totalAmount : "" : ""}</p>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={(e) => {
              e.preventDefault();
              dispatch(hideAgentIntentModal())
              localStorage.removeItem("amount")
              // dispatch(submitModal("open", false));
              setLoader(false);
            }}
          >
            Cancel
          </Button>
          {loader ? (
            <Button disabled={true}>
              <CircleLoader size={30} color="#2E0968" />
            </Button>
          ) : (
            <Button
              style={{
                background: "#fda94f",
                borderRadius: "1px solid #fda94f",
                color: "#000",
              }}
              onClick={submitFinal}
            >
              {transSuccess ? "Submit" : modal.verify ? "Verify" : "Submit"}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </div>
  );
}
