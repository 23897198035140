import { auth } from "./firebase"
import { GoogleAuthProvider, signInWithPopup, signInWithRedirect } from "firebase/auth"


export const doSignInWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  const result = await signInWithPopup(auth, provider)

  return result
}

export const onGoogleSignInRedirect = () => {
  const provider = new GoogleAuthProvider();
  signInWithRedirect(auth, provider);
};

export const doSignOut = () => {
  return auth.signOut()
}
