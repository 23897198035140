import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import html2pdf from "html2pdf.js";
import { txStatusQuery } from "../../store/actions/Coralpay";
import { requeryCoralTxn } from "../../store/actions/finalPayment";
import { removeString, toTwoDecimal } from "../../utils/commonFunctions";
import { WalletBalance } from "../../store/actions/wallet";
import { useReactToPrint } from "react-to-print";
import { Button } from "reactstrap";
import "./receipt.css"
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export default function CoralReceipt() {
  const dispatch = useDispatch();
  const componentRef = useRef();
  const { reference } = useParams();
  const history = useHistory();
  const finalData = useSelector((state) => state.finalPayment);
  const modalSet = useSelector(state => state.modalSet);
  const requeryData = useSelector((state) => state.finalPayment.requery);
  const productAccount = useSelector(state => state.invokeCoralpay.productAccount);
  const paymentIntent = useSelector((state) => state.trans.paymentIntent);
  const intentData = useSelector((state) => state.trans.nonUserIntent);
  const transSuccess = useSelector((state) => state.trans.success);
  const walletUserInit = useSelector((state) => state.trans.walletUserInit);
  const tranStatus_query = useSelector(state => state.invokeCoralpay.txStatus_Query);
  const wallet = useSelector(state => state.wallet);
  const tranStatus_requery = useSelector(state => state.invokeCoralpay.txrequery);

  const [customerName, setCustomerName] = useState(null);
  const [customerMessageData, setCustomerMessageData] = useState(null);
  const [blocking, setBlocking] = useState(false);

  const handleDownload = useCallback(() => {
    const input = componentRef.current;
    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: [58, 210]  // Adjust this to match your POS paper size
      });
      pdf.addImage(imgData, 'PNG', 0, 0, 58, 0);
      // pdf.save("reload.pdf")
      pdf.save("Reload_" + new Date().toISOString().split('T')[0] +"_.pdf");
    });
  }, []);

  useEffect(() => {
    if (transSuccess && tranStatus_requery) {
      let val = transSuccess ? removeString(transSuccess.data.dataObject) : "";
      let address = val ? val.responseData.customer.customerAddress : localStorage.getItem("address") || "";
      let customerName = val ? val.responseData.customer.customerName : localStorage.getItem("customerName") || "";

      dispatch(WalletBalance(wallet.user ? wallet.user.accountNo : ""));
      setCustomerMessageData(tranStatus_requery.responseData.customerMessage);
      setCustomerName(customerName);
    }
  }, [transSuccess, tranStatus_requery, dispatch, wallet.user]);

  useEffect(() => {
    dispatch(requeryCoralTxn(reference));
    dispatch(txStatusQuery(reference));
  }, [dispatch, reference]);

  const handleBack = (e) => {
    e.preventDefault();
    localStorage.removeItem("ref");
    window.location.href = `${process.env.PUBLIC_URL}/`;
  };

  useEffect(() => {
    if (finalData.data || requeryData) {
      const handleBeforeUnload = () => {
        setBlocking(true);
        return "Are you sure you want to leave?";
      };

      window.addEventListener('beforeunload', handleBeforeUnload);

      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }
  }, [finalData.data, requeryData]);

  return (
    <div className="container mx-auto px-4">
      <div className="flex justify-between items-center">
        <Button onClick={handleBack} variant="outline">
          Go back
        </Button>
        <Button onClick={handleDownload} style={{ backgroundColor: "green", color: "#fff" }}>
          Download
        </Button>
      </div>
      <style jsx>{`
        @page {
          size: 58mm auto;
          margin: 5mm;
        }
        @media print {
          body {
            font-family: Arial, sans-serif;
            font-size: 10px;
            color: #000;
          }
          .receipt-header {
            text-align: center;
            font-weight: bold;
            margin-bottom: 5mm;
          }
          .receipt-content {
            line-height: 1.2;
          }
          .receipt-footer {
            text-align: center;
            font-size: 8px;
            margin-top: 5mm;
          }
        }
      `}</style>
      <div className="bg-white p-4 rounded-lg shadow-lg max-w-2xl mx-auto" style={{ maxWidth: "35%" }} ref={componentRef}>
        <div className="text-center mb-2">
          <p className="font-bold">
            Invoice #: {tranStatus_query ? tranStatus_query.data.reference : ""}
          </p>
          <p className="text-sm">
            Transaction Date: {tranStatus_query ? new Date(tranStatus_query.data.transactiondate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) : ""}
          </p>
        </div>
        <div className="text-2xl font-bold"><h4 className="text-center m-2">Reload.ng</h4></div>
        <div className="space-y-2 text-center mb-2">
          <p>Email: {tranStatus_query ? tranStatus_query.data.email : ""}</p>
          {productAccount && <p>Customer message: {productAccount.productId.productname} was successful</p>}
          {tranStatus_requery && <p>Account Number: {tranStatus_requery.responseData.customer. accountNumber}</p>}
          {tranStatus_requery?.responseData.tokenData && (
            <>
              {productAccount && <p>Address: {productAccount.address}</p>}
              {productAccount && <p>Customer name: {productAccount.accountName}</p>}
              <p>Token: <b>{tranStatus_requery.responseData.tokenData.stdToken.value}</b></p>
              <p>Unit: {tranStatus_requery.responseData.tokenData.stdToken.units}</p>
            </>
          )}
          <p>Status: Transaction successful</p>
          {/* <p>Status: {tranStatus_query ? tranStatus_query.data.clientpaymentId.responseMessage : ""}</p> */}
          {tranStatus_requery?.responseData.tokenData?.kct1 && tranStatus_requery.responseData.tokenData.kct2 && (
            <>
              <p>kct1: {tranStatus_requery.responseData.tokenData.kct1}</p>
              <p>kct2: {tranStatus_requery.responseData.tokenData.kct2}</p>
            </>
          )}
        </div>
        <div className="invoice-body">
          <table className="table">
            <thead>
              <tr>
                <th>Description</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {/* <td>MTN</td> */}
                <td className="py-2">{tranStatus_requery ? tranStatus_requery.responseData.billerName : ""}</td>
                {/* <td>{tranStatus_requery?.responseData?.billerName || finalData.data ? finalData.data.result.productResult.responseData.responseData.packageName : ""}</td> */}
                <td>
                  {/* {toTwoDecimal(50)} */}
                  ₦{tranStatus_query ? tranStatus_query.data.amount : walletUserInit ? walletUserInit.result.amount : ""}
                </td>
              </tr>
              <tr>
                <td>Convenience fee</td>
                <td>
                  {/* {toTwoDecimal(50)} */}
                  ₦{tranStatus_query ? tranStatus_query.data.fee : walletUserInit ? walletUserInit.result.fee : ""}
                </td>
              </tr>
            </tbody>
          </table>
          <div className="flex-table" style={{ display: "flex", justifyContent: "center" }}>
            <div className="flex-column">
              <table className="table-subtotal">
                <tbody>
                  <tr>
                    <td>Amount paid</td>
                    <td>
                      {/* {toTwoDecimal(150)} */}
                      {"₦" + tranStatus_query?.data?.amount ||
                        walletUserInit?.result?.amount ||
                        (paymentIntent?.result ? toTwoDecimal(paymentIntent.result.totalAmount) : "")}
                    </td>
                  </tr>
                  <tr>
                    <td>Convenience fee</td>
                    <td>₦{tranStatus_query ? tranStatus_query.data.fee : walletUserInit ? walletUserInit.result.fee : ""}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="invoice-total-amount" style={{ display: "flex", justifyContent: "center" }}>
            <p><b>
              Total: {"₦" +
                tranStatus_query?.data?.price ??
                walletUserInit?.result?.totalAmount ??
                (paymentIntent?.result ? toTwoDecimal(paymentIntent.result.totalAmount) : "")
              }
            </b></p>
          </div>
        </div>
        <small>*******************************************************************************************</small>
        <div className="text-center text-sm">
          <p>❤ Thanks for your patronage</p>
        </div>
      </div>
    </div>
  );
}